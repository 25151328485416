import * as Styled from './OxSurveyRoadline.styled';

import React from 'react';

type TProps = {
    activeIndex: number;
    stepsNumber: number;
};

export const OxSurveyRoadline = (props: TProps): JSX.Element => (
    <Styled.Container>
        {new Array(props.stepsNumber).fill(0).map((_, index) => (
            <Styled.StepCircle
                key={index}
                active={index === props.activeIndex}
                completed={index < props.activeIndex}
            >
                {index + 1}
            </Styled.StepCircle>
        ))}
    </Styled.Container>
);
