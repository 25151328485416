import { EBreakpoints } from 'src/config/enums';

type TDragAndDropOptions = {
    onDrop: (itemId: string, dropzoneId: string, data?: object) => void;
    onRemove: (itemId: string, dropzoneId: string, data?: object) => void;
};

type TDropzone = {
    snapX: number;
    snapY: number;
    elem: HTMLElement;
};

type TDraggable = {
    dropzone: TDropzone | null;
    elem: HTMLElement;
};

type TDraggableBaseCoords = {
    x: number;
    y: number;
};

function getOffset(elem: EventTarget): { top: number; left: number } {
    let offsetTop = 0;
    let offsetLeft = 0;
    do {
        if (elem instanceof HTMLDivElement) {
            if (!isNaN(elem.offsetTop)) {
                offsetTop += elem.offsetTop;
            }
            if (!isNaN(elem.offsetLeft)) {
                offsetLeft += elem.offsetLeft;
            }
        }
    } while ((elem = elem.offsetParent));

    return { top: offsetTop, left: offsetLeft };
}

export const DragAndDrop = (
    draggableElements: Array<HTMLDivElement>,
    dropzoneElements: Array<HTMLDivElement>,
    options: TDragAndDropOptions
): {
    reset: () => void;
    setData: (data: object) => void;
    update: () => void;
} => {
    let data = {};
    let draggedElement: TDraggable | undefined = undefined;
    let draggedElementBaseCoords: TDraggableBaseCoords | undefined = undefined;
    let dropzones: Array<TDropzone> = [];
    const draggables: Array<TDraggable> = [];
    const dropzoneSnapArea = document.clientWidth < EBreakpoints.Small ? 50 : 100;

    const getEmptyDropzones = (): Array<TDropzone> =>
        dropzones.filter(
            (dz) =>
                !draggables.find(
                    (dg) =>
                        dg.dropzone?.elem.getAttribute('dnd-dropzone-id') ===
                        dz.elem.getAttribute('dnd-dropzone-id')
                )
        );

    const onMouseUp = (): void => {
        if (draggedElement) {
            if (draggedElement.dropzone) {
                const draggedElementDataIndex = draggedElement.elem.getAttribute('data-index');
                const dropzoneElementDataIndex =
                    draggedElement.dropzone.elem.getAttribute('data-index');

                if (
                    typeof draggedElementDataIndex === 'string' &&
                    typeof dropzoneElementDataIndex === 'string'
                ) {
                    if (draggedElementBaseCoords) {
                        const dx = draggedElement.dropzone.snapX - draggedElementBaseCoords.x;
                        const dy = draggedElement.dropzone.snapY - draggedElementBaseCoords.y;
                        draggedElement.elem.style.transition = `100ms`;
                        draggedElement.elem.style.transform = `translate(${dx}px, ${dy}px)`;
                    }

                    draggedElement.dropzone.elem.classList.remove('active');
                    draggedElement.dropzone.elem.classList.add('occupied');
                    options.onDrop(draggedElementDataIndex, dropzoneElementDataIndex, data);
                }
            } else {
                const emptyDropzones = getEmptyDropzones();
                emptyDropzones.forEach((dz) => {
                    dz.elem.classList.remove('occupied');
                });
                draggedElement.elem.style.transform = `translate(0px, 0px)`;
            }
            document.body.classList.remove('dragging-event');
            draggedElement = undefined;
        }
    };

    const onMouseDown = (e: MouseEvent | TouchEvent): void => {
        if (e.target === null) return;
        const target = e.target as HTMLDivElement;
        document.body.classList.add('dragging-event');

        const dragged = draggables.find(
            (item) =>
                item.elem.getAttribute('dnd-draggable-id') ===
                target.getAttribute('dnd-draggable-id')
        );
        draggedElement = dragged;

        draggedElementBaseCoords = {
            x: getOffset(e.target).left + target.clientWidth / 2,
            y: getOffset(e.target).top + target.clientHeight / 2
        };
    };

    const onMouseMove = (e: MouseEvent | TouchEvent): void => {
        if (!draggedElement || !draggedElementBaseCoords) return;
        const pageX = e instanceof MouseEvent ? e.pageX : e.changedTouches[0].pageX;
        const pageY = e instanceof MouseEvent ? e.pageY : e.changedTouches[0].pageY;
        const dx = pageX - draggedElementBaseCoords.x;
        const dy = pageY - draggedElementBaseCoords.y;
        let dist = Number.MAX_SAFE_INTEGER;
        const emptyDropzones = getEmptyDropzones();
        if ((draggedElement as TDraggable).dropzone) {
            emptyDropzones.push((draggedElement as TDraggable).dropzone);
        }
        let selectedDropzone;
        emptyDropzones.forEach((dropzone) => {
            const a = pageX - dropzone.snapX;
            const b = pageY - dropzone.snapY;
            const c = Math.sqrt(a * a + b * b);
            if (c < dropzoneSnapArea && c < dist) {
                dist = c;
                selectedDropzone = dropzone;
            } else {
                dropzone.elem.classList.remove('active');
                dropzone.elem.classList.remove('occupied');
            }
        });

        if (selectedDropzone) {
            (draggedElement as TDraggable).dropzone = selectedDropzone;
            // (draggedElement as TDraggable).elem.style.transition = `100ms`;
            // dx = selectedDropzone.snapX - draggedElementBaseCoords.x;
            // dy = selectedDropzone.snapY - draggedElementBaseCoords.y;

            selectedDropzone = null;
        } else {
            if (draggedElement.dropzone) {
                const draggedElementDataIndex = draggedElement.elem.getAttribute('data-index');
                const dropzoneElementDataIndex =
                    draggedElement.dropzone.elem.getAttribute('data-index');
                if (
                    typeof draggedElementDataIndex === 'string' &&
                    typeof dropzoneElementDataIndex === 'string'
                ) {
                    options.onRemove(draggedElementDataIndex, dropzoneElementDataIndex, data);
                }
            }
            draggedElement.dropzone = null;
            draggedElement.elem.style.transition = `0ms`;
        }
        draggedElement.dropzone?.elem.classList.add('active');
        draggedElement.elem.style.transform = `translate(${dx}px, ${dy}px)`;
    };

    const createDropzoneElement = (elem: HTMLElement, id: number): TDropzone => {
        elem.setAttribute('dnd-dropzone-id', `${id}`);
        return {
            snapX: getOffset(elem).left + elem.clientWidth / 2,
            snapY: getOffset(elem).top + elem.clientHeight / 2,
            elem
        };
    };

    const createDraggableElement = (elem: HTMLElement, id: number): TDraggable => {
        elem.setAttribute('dnd-draggable-id', `${id}`);
        return {
            dropzone: null,
            elem
        };
    };

    const createDropzones = (): void => {
        dropzoneElements.forEach((elem, index) => {
            dropzones.push(createDropzoneElement(elem, index));
        });
    };

    const createDraggables = (): void => {
        draggableElements.forEach((elem, index) => {
            draggables.push(createDraggableElement(elem, index));
        });
    };

    const createEvents = (): void => {
        document.body.addEventListener('mouseup', onMouseUp);
        document.body.addEventListener('touchend', onMouseUp);
        document.body.addEventListener('mousemove', onMouseMove);
        document.body.addEventListener('touchmove', onMouseMove);
        draggableElements.forEach((item) => {
            item.addEventListener('mousedown', onMouseDown);
            item.addEventListener('touchstart', onMouseDown);
        });
    };

    const reset = (): void => {
        draggables.forEach((item) => {
            item.dropzone?.elem.classList.remove('occupied');
            item.dropzone = null;
            item.elem.style.transform = `translate(0px, 0px)`;
        });
    };

    const setData = (newData: object): void => {
        data = {
            ...data,
            ...newData
        };
    };

    const update = (): void => {
        dropzones = dropzones.map((item) => ({
            ...item,
            snapX: getOffset(item.elem).left + item.elem.clientWidth / 2,
            snapY: getOffset(item.elem).top + item.elem.clientHeight / 2
        }));
    };

    createDropzones();
    createDraggables();
    createEvents();

    return {
        reset,
        setData,
        update
    };
};
