import styled, { css } from 'styled-components';
import { fluidSizing } from 'src/utils';
import { createTextStyle } from 'src/utils/createTextStyle';
import { EFonts, EFontWeight } from 'src/config/enums';

export const PersonalitySurveyContainer = styled.div(
    ({ theme }) => css`
        background: ${theme.colors.secondary.main};
        display: flex;
        flex-direction: column;
        & > button:last-of-type {
            width: 100%;
        }
        ${fluidSizing([
            { prop: 'padding-top', values: [28, 28, 28] },
            { prop: 'padding-bottom', values: [28, 28, 28] },
            { prop: 'padding-left', values: [28, 28, 28] },
            { prop: 'padding-right', values: [28, 28, 28] },
            { prop: 'margin-bottom', values: [50, 50, 50] }
        ])}
    `
);

export const PersonalitySurveyTitle = styled.h2`
    text-align: center;
    ${createTextStyle(EFonts.Hatton, EFontWeight.Normal, [18, 35, 35], [37, 37, 37])}
    ${fluidSizing([{ prop: 'padding-bottom', values: [0, 15, 15] }])}
`;

export const CollapsedContainerTitle = styled.button<{ isOpen: boolean }>(
    ({ theme, isOpen }) => css`
        width: fit-content;
        margin: 0 auto;
        color: ${theme.colors.primary.main};
        ${createTextStyle(EFonts.Montserrat, EFontWeight.Normal, [10, 10, 10], [12, 12, 12])}
        ${fluidSizing([{ prop: 'padding', values: [5, 5, 5] }])}
    ${!isOpen &&
        css`
            ${fluidSizing([{ prop: 'margin-bottom', values: [15, 15, 15] }])}
        `}
      ${isOpen &&
        css`
            ${fluidSizing([{ prop: 'margin-bottom', values: [5, 0, 0] }])}
        `}
    svg {
            ${fluidSizing([
                { prop: 'height', values: [10, 10, 10] },
                { prop: 'margin-left', values: [4, 4, 4] }
            ])}
            transform: rotate(90deg) translateX(15%);
            ${isOpen &&
            css`
                transform: rotate(-90deg) translateX(-15%);
            `}
        }
    `
);

export const CollapsedContainer = styled.div<{ collapse: boolean }>(
    ({ collapse }) => css`
        height: attr(data-height);
        ${collapse &&
        css`
            height: 0;
            overflow: hidden;
        `}
    `
);

export const SpinnerContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    ${fluidSizing([
        { prop: 'margin-top', values: [20, 20, 20] },
        { prop: 'margin-bottom', values: [20, 20, 20] }
    ])}
`;

export const Description = styled.p`
    white-space: pre-line;
    ${createTextStyle(EFonts.Montserrat, EFontWeight.Light, [12, 12, 12], [16, 16, 16])}
    &:last-of-type {
        font-weight: ${EFontWeight.Bold};
    }
    ${fluidSizing([{ prop: 'padding-bottom', values: [15, 15, 15] }])}
`;

export const Container = styled.div``;

export const Header = styled.div(
    ({ theme }) => css`
        display: flex;
        justify-content: space-between;
        button {
            color: ${theme.colors.primary.main};
        }
        button:nth-of-type(2) {
            opacity: 0;
            visibility: none;
            cursor: default;
            height: 1px;
        }
        ${theme.breakpoints.only('xs')} {
            flex-direction: column;
            ${fluidSizing([
                { prop: 'margin-left', values: [28, null, null] },
                { prop: 'margin-right', values: [28, null, null] }
            ])}
        }
        ${fluidSizing([{ prop: 'margin-bottom', values: [15, 15, 15] }])}
    `
);

export const HeaderName = styled.h2(
    ({ theme }) => css`
        border-bottom: 1px solid ${theme.colors.basic.black};
        ${theme.breakpoints.only('xs')} {
            text-align: center;
        }
        ${createTextStyle(EFonts.Hatton, EFontWeight.SemiBold, [18, 27, 27], [34, 34, 34])}
        ${fluidSizing([
            { prop: 'padding-top', values: [5, 0, 0] },
            { prop: 'padding-bottom', values: [5, 15, 15] }
        ])}
    `
);
