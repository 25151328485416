import * as Styled from './OxArchetypesDragbox.styled';

import React, { createRef, useEffect, useRef } from 'react';

import { DragAndDrop } from 'src/services/drag-and-drop/DragAndDrop';
import { TAnswer } from 'src/services/api/api.types';

type TProps = {
    statement: {
        id: number;
        name: string;
        statements: Array<{ id: number; label: string }>;
    };
    dnd: any;
    setAnswers: (answer: (prevAnswers) => TAnswer[]) => void;
    setDnd: (dnd: any) => void;
};

export const OxArchetypesDragbox = (props: TProps): JSX.Element => {
    const ratingWrappersRefs = useRef<{
        [key: number]: React.RefObject<HTMLDivElement>;
    }>({
        0: createRef<HTMLDivElement>(),
        1: createRef<HTMLDivElement>(),
        2: createRef<HTMLDivElement>(),
        3: createRef<HTMLDivElement>(),
        4: createRef<HTMLDivElement>()
    });
    const behaviorDropzoneRefs = useRef<{
        [key: number]: React.RefObject<HTMLDivElement>;
    }>({
        0: createRef<HTMLDivElement>(),
        1: createRef<HTMLDivElement>(),
        2: createRef<HTMLDivElement>(),
        3: createRef<HTMLDivElement>()
    });

    const getDraggableElements = () =>
        Object.keys(ratingWrappersRefs.current).map(
            (key) => ratingWrappersRefs.current[key].current.childNodes[0]
        );

    const getDropzoneElements = () =>
        Object.keys(behaviorDropzoneRefs.current).map(
            (key) => behaviorDropzoneRefs.current[key].current
        );

    useEffect(() => {
        const draggableElements = getDraggableElements();
        const dropzoneElements = getDropzoneElements();
        const dnd = DragAndDrop(draggableElements, dropzoneElements, {
            onDrop: (ratingId: string, dropzoneId: string, data?: object) => {
                const answer: TAnswer = {
                    statement_id: parseInt(dropzoneId),
                    statement_set: data?.statementSetId,
                    position: parseInt(ratingId)
                };
                props.setAnswers((prevAnswers) => {
                    const answers = prevAnswers.filter((item) => {
                        if (item.statement_set === answer.statement_set) {
                            return item.position !== answer.position;
                        }
                        return true;
                    });

                    return [...answers, answer];
                });
            },
            onRemove: (ratingId, dropzoneId, data) => {
                props.setAnswers((prevAnswers) =>
                    prevAnswers.filter((item) => {
                        if (item.statement_set === data?.statementSetId) {
                            return (
                                item.statement_id !== parseInt(dropzoneId) &&
                                item.position !== parseInt(ratingId)
                            );
                        }
                        return true;
                    })
                );
            }
        });
        props.setDnd(dnd);
        dnd.setData({ statementSetId: props.statement.id });
    }, [ratingWrappersRefs.current[0], behaviorDropzoneRefs.current[0]]);

    useEffect(() => {
        if (props.dnd) props.dnd.setData({ statementSetId: props.statement.id });
    }, [props.statement]);

    return (
        <Styled.Container>
            <Styled.RatingValues>
                <span>Least Like Me</span>
                {new Array(5).fill(0).map((_, index) => (
                    <Styled.DraggableNumberWrapper
                        key={index}
                        ref={ratingWrappersRefs.current[index]}
                    >
                        <Styled.DraggableNumber data-index={index}>
                            {index + 1}
                        </Styled.DraggableNumber>
                    </Styled.DraggableNumberWrapper>
                ))}
                <span>Most Like Me</span>
            </Styled.RatingValues>
            <Styled.BehavioursList>
                {props.statement.statements.map((item, index) => (
                    <Styled.Behaviour key={index}>
                        <Styled.BehaviourDropZone
                            className="dropzone"
                            data-index={item.id}
                            ref={behaviorDropzoneRefs.current[index]}
                        ></Styled.BehaviourDropZone>
                        <Styled.BehaviourTitle>{item.label}</Styled.BehaviourTitle>
                    </Styled.Behaviour>
                ))}
            </Styled.BehavioursList>
        </Styled.Container>
    );
};
