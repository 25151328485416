import { TAnswer, TRespondent, TSurvey } from 'src/services/api/api.types';
import { apiGetCall, apiPostCall } from 'src/services/api/api.core';
import { EApiEndpoint } from 'src/config/enums';
import { applyValues } from 'src/utils/applyValues';

export const psychologicalGetSurvey = async (): Promise<TSurvey | null> => {
    return await apiGetCall(EApiEndpoint.PsychologicalSurvey);
};

export const psychologicalGetArchetype = async (values: {
    respondentId: number;
}): Promise<string | null> => {
    return await apiGetCall(applyValues(EApiEndpoint.PsychologicalArchetype, values));
};

export const psychologicalPostSurvey = async (
    values: {
        surveyId: number;
    },
    body: {
        respondent: TRespondent;
        answers: TAnswer[];
    }
): Promise<number | null> => {
    return await apiPostCall(
        applyValues(EApiEndpoint.PsychologicalSurveyById, values),
        JSON.stringify(body)
    );
};

export const psychologicalCheckRespondent = async (
    values: {
        surveyId: number;
    },
    body: {
        respondent: TRespondent;
    }
): Promise<boolean | null> => {
    return await apiPostCall(
        applyValues(EApiEndpoint.PsychologicalSurveyCheckRespondent, values),
        JSON.stringify(body)
    );
};
