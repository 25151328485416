import { EFontWeight, EFonts } from 'src/config/enums';
import styled, { css } from 'styled-components';

import { createTextStyle } from 'src/utils/createTextStyle';
import { fluidSizing } from 'src/utils';

export const Container = styled.div(
    ({ theme }) => css`
        display: flex;
        justify-content: space-between;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            width: 100%;
            height: 1px;
            background-color: ${theme.colors.basic.disabledTile};
            z-index: -1;
        }
        ${fluidSizing([
            { prop: 'margin-bottom', values: [15, 15, 15] },
            { prop: 'margin-left', values: [28, null, null] },
            { prop: 'margin-right', values: [28, null, null] }
        ])}
    `
);

export const StepCircle = styled.div<{ active: boolean; completed: boolean }>(
    ({ theme, active, completed }) => css`
        background-color: ${theme.colors.secondary.variant};
        color: ${theme.colors.basic.white};
        border-radius: 100%;
        text-align: center;
        ${active &&
        css`
            background-color: ${theme.colors.basic.black};
        `}
        ${completed &&
        css`
            background-color: ${theme.colors.primary.main};
        `}
    ${createTextStyle(EFonts.Montserrat, EFontWeight.SemiBold, [11, 11, 11], [22, 32, 32])}
    ${fluidSizing([
            { prop: 'width', values: [22, 32, 32] },
            { prop: 'height', values: [22, 32, 32] }
        ])}
    `
);
