import { EFontWeight, EFonts } from 'src/config/enums';
import styled, { css } from 'styled-components';

import { createTextStyle } from 'src/utils/createTextStyle';
import { fluidSizing } from 'src/utils';

export const Container = styled.div`
    position: relative;
`;

export const RatingValues = styled.div(
    ({ theme }) => css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border: 1px solid ${theme.colors.basic.ouronyxLogo};
        span {
            color: ${theme.colors.primary.main};
            ${createTextStyle(EFonts.Montserrat, EFontWeight.Medium, [12, 12, 12], [16, 16, 16])}
        }
        ${theme.breakpoints.only('xs')} {
            span {
                position: absolute;
                top: 0;
                &:first-of-type {
                    left: 0;
                }
                &:last-of-type {
                    right: 0;
                }
            }
        }
        ${fluidSizing([
            { prop: 'height', values: [50, 50, 50] },
            { prop: 'border-radius', values: [25, 25, 25] },
            { prop: 'padding-left', values: [15, 15, 15] },
            { prop: 'padding-right', values: [15, 15, 15] },
            { prop: 'margin-top', values: [15, 0, 0] },
            { prop: 'margin-bottom', values: [15, 15, 15] }
        ])}
    `
);

export const DraggableNumberWrapper = styled.div(
    ({ theme }) => css`
        border-radius: 100%;
        position: relative;
        z-index: 10;
        ${theme.breakpoints.up('xs')} {
            width: 40px;
            height: 40px;
            box-shadow: 0 0 0 1px ${theme.colors.primary.main};
        }
    `
);

export const DraggableNumber = styled.div(
    ({ theme }) => css`
        border-radius: 100%;
        background: ${theme.colors.primary.main};
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${theme.colors.basic.white};
        touch-action: none;
        user-select: none;
        z-index: 999;
        cursor: grab;
        font-family: ${EFonts.Hatton};
        font-weight: ${EFontWeight.Light};
        font-size: 16px;
        line-height: 40px;
        ${theme.breakpoints.up('xs')} {
            width: 36px;
            height: 36px;
            left: 2px;
            top: 2px;
        }
    `
);

export const BehavioursList = styled.div(
    ({ theme }) => css`
        display: flex;
        ${theme.breakpoints.only('xs')} {
            flex-direction: column;
        }
        ${theme.breakpoints.up('xs')} {
            flex-wrap: wrap;
            justify-content: space-between;
        }
    `
);

export const Behaviour = styled.div(
    ({ theme }) => css`
        display: flex;
        align-items: center;
        width: 48%;
        background-color: ${theme.colors.basic.white};
        ${theme.breakpoints.only('xs')} {
            width: 100%;
        }
        ${fluidSizing([
            { prop: 'height', values: [60, 60, 60] },
            { prop: 'margin-bottom', values: [15, 15, 15] },
            { prop: 'padding-right', values: [15, 15, 15] },
            { prop: 'padding-left', values: [15, 15, 15] },
            { prop: 'border-radius', values: [31, 31, 31] }
        ])}
    `
);

export const BehaviourDropZone = styled.div(
    ({ theme }) => css`
        border-radius: 100%;
        width: 34px;
        height: 34px;
        box-shadow: 0 0 0 1px ${theme.colors.primary.main};
        flex-shrink: 0;
        transition: 0.3s;
        &.active {
            background-color: ${theme.colors.primary.variant};
        }
        &.occupied {
            box-shadow: none;
        }
    `
);

export const BehaviourTitle = styled.div`
    ${createTextStyle(EFonts.Montserrat, EFontWeight.Medium, [12, 12, 12], [16, 16, 16])}
    ${fluidSizing([{ prop: 'margin-left', values: [15, 15, 15] }])}
`;
