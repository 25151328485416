import React, { useContext, useEffect, useRef, useState } from 'react';
import { OxSurveyRoadline } from 'src/components/OxSurvey/components/OxSurveyRoadline';
import { OxIcon } from 'src/components/OxIcon';
import { OxArchetypesDragbox } from 'src/components/OxSurvey/components/OxArchetypesDragbox';
import { EInputTheme, OxThemedInput } from 'src/components/OxThemedInput';
import { OxButton } from 'src/components/OxButton';
import {
    psychologicalGetArchetype,
    psychologicalGetSurvey,
    psychologicalPostSurvey
} from 'src/services/api/psychological';
import { navigate } from '@reach/router';
import { TAnswer } from 'src/services/api/api.types';
import { TPersonalityArchetypes } from 'src/components/OxSurvey';
import { OxSpinner } from 'src/components/OxSpinner';
import * as Styled from './OxPersonalityArchetypes.styled';
import { AlertContext, EAlertVariant } from 'src/context/AlertContext';
import { OxBackButton } from 'src/components/OxBackButton';

type TPersonalityArchetypesProps = {
    personalityData: TPersonalityArchetypes;
    reception?: boolean;
    onNext?: () => void;
    header?: string;
    onBack?: () => void;
};

export const OxPersonalityArchetypes = (props: TPersonalityArchetypesProps): JSX.Element => {
    const [answers, setAnswers] = useState<TAnswer[]>([]);
    const [surveyData, setSurveyData] = useState();
    const [dnd, setDnd] = useState<any>();
    const [collapseTutorial, setCollapseTutorial] = useState(false);
    const [loading, setLoading] = useState(false);
    const collapsedContainerRef = useRef();
    const [activeIndex, setActiveIndex] = useState(0);
    const { showAlert } = useContext(AlertContext);

    const handleNextButtonClick = async (): Promise<void> => {
        if ((activeIndex + 1) * 4 !== answers.length) {
            showAlert({
                type: EAlertVariant.Error,
                header: 'Alert',
                title: 'Please drag 4 values'
            });
        }
        if (answers.length < (activeIndex + 1) * 4) return;
        if (activeIndex === surveyData.statementSets.length - 1) {
            setLoading(true);
            const respondentId = await psychologicalPostSurvey(
                {
                    surveyId: 1
                },
                {
                    respondent: {
                        email: props.personalityData?.email,
                        firstname: props.personalityData?.firstName,
                        lastname: props.personalityData?.lastName,
                        mobile: props.personalityData?.mobile,
                        gender: props.personalityData?.gender,
                        newsletter: props.personalityData?.newsletter
                    },
                    answers: answers
                }
            );
            if (respondentId) {
                if (props.reception && props.onNext) {
                    props.onNext();
                } else {
                    const archetype = await psychologicalGetArchetype({
                        respondentId: respondentId.respondent_id
                    });
                    if (archetype) {
                        navigate(`/${archetype}/`);
                    }
                }
                setLoading(false);
            }
        } else {
            if (!collapseTutorial) {
                setCollapseTutorial(true);
            }
            window.scrollTo({ top: 0, behavior: 'smooth' });
            dnd.reset();
            setActiveIndex((prev) => prev + 1);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (dnd) dnd.update();
    }, [collapseTutorial]);

    useEffect(() => {
        async function fetchData(): Promise<void> {
            const survey = await psychologicalGetSurvey();
            if (survey) {
                setSurveyData(survey[0]);
            }
        }

        fetchData();
    }, []);

    const onBack = () => {
        if (activeIndex === 0 && props.onBack) {
            props.onBack();
        } else {
            answers.splice((activeIndex - 1) * 4 + 1, 4);
            setActiveIndex((prev) => prev - 1);
        }
    };

    return (
        <Styled.Container>
            {props.header && (
                <Styled.Header>
                    <OxBackButton onClick={onBack} />
                    <Styled.HeaderName>{props.header}</Styled.HeaderName>
                    <OxBackButton />
                </Styled.Header>
            )}
            {surveyData ? (
                <>
                    <OxSurveyRoadline
                        activeIndex={activeIndex}
                        stepsNumber={surveyData.statementSets.length}
                    />
                    <Styled.PersonalitySurveyContainer>
                        <Styled.PersonalitySurveyTitle>
                            {surveyData.label}
                        </Styled.PersonalitySurveyTitle>
                        <Styled.CollapsedContainerTitle
                            onClick={(): void => setCollapseTutorial((prev) => !prev)}
                            isOpen={!collapseTutorial}
                        >
                            How it works <OxIcon name="arrow-1" />
                        </Styled.CollapsedContainerTitle>
                        <Styled.CollapsedContainer
                            collapse={collapseTutorial}
                            ref={collapsedContainerRef}
                        >
                            <Styled.Description>
                                We would like you to evaluate 6 different combinations of 4
                                behavioural statements. Please indicate to what extent these
                                statements reflect your preferential behaviour by ordering them from
                                1 to 5 (1=Least like me; 5=Most like me).
                            </Styled.Description>
                            <Styled.Description>
                                Please drag the number circles and attach them to the desired
                                statement.
                            </Styled.Description>
                        </Styled.CollapsedContainer>
                        <OxArchetypesDragbox
                            statement={surveyData.statementSets[activeIndex]}
                            setAnswers={setAnswers}
                            setDnd={setDnd}
                            dnd={dnd}
                        />
                        <OxThemedInput theme={EInputTheme.BackgroundGoldAlternative}>
                            <OxButton
                                onClick={handleNextButtonClick}
                                loading={loading}
                                disabled={loading}
                                icon
                            >
                                NEXT
                            </OxButton>
                        </OxThemedInput>
                    </Styled.PersonalitySurveyContainer>
                </>
            ) : (
                <Styled.SpinnerContainer>
                    <OxSpinner />
                </Styled.SpinnerContainer>
            )}
        </Styled.Container>
    );
};
